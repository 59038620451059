<!--客户管理-组织架构-->
<template>
  <section>
    <!-- 用户类型 typ 1 系统类型；2 经销代理；3 行政单位；4 餐饮单位-->
    <el-tabs v-model="filter.Type" @tab-click="handleTabClick">
      <el-tab-pane label="餐饮单位" name="4"></el-tab-pane>
      <el-tab-pane label="行政单位" name="3"></el-tab-pane>
      <el-tab-pane label="经销单位" name="2"></el-tab-pane>
      <el-tab-pane label="系统用户" name="1"></el-tab-pane>
    </el-tabs>
    <!-- 工具条 -->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form :inline="true" size="mini" :model="filter">
        <el-form-item>
          <el-input
            v-model="filter.val"
            placeholder="关键字搜索"
            @keyup.native.enter="getData"
          >
            <el-select
              v-model="filter.field"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="单位名称" value="Org"></el-option>
              <el-option label="负责人" value="Name"></el-option>
              <el-option label="联系电话" value="Mobile"></el-option>
            </el-select>
            <!-- <el-button
              slot="append"
              icon="el-icon-refresh"
              @click="handleRefresh"
            ></el-button> -->
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-cascader
            placeholder="上级单位"
            @change="getData"
            v-model="filter.Pid"
            :options="customerTree"
            :props="props"
            clearable
            filterable
          />
        </el-form-item>
        <el-form-item v-if="filter.Type == 2 || filter.Type == 3">
          <el-cascader
            placeholder="区域"
            @change="getData"
            v-model="filter.Areas"
            :options="areaTree"
            :props="selAreaProps"
            clearable
            filterable
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleExport">导出</el-button>
          <el-button type="primary" @click="handleAdd">新增</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <!-- 列表 -->
    <el-col :span="24">
      <el-table
        :data="datalist.content"
        size="mini"
        border
        highlight-current-row
        v-loading="loading"
        @selection-change="selsChange"
        :max-height="clientHeight - 40"
        style="width: 100%"
      >
        <el-table-column type="selection"/>
        <el-table-column type="index" label="#" align="center" width="55"/>
        <el-table-column
          prop="Org"
          label="单位名称"
          width="150"
          show-overflow-tooltip
          header-align="center"
        >
          <template slot-scope="scope">
            <el-popover
              v-if="scope.row.Typ === 2 || scope.row.Typ === 3"
              trigger="hover"
              placement="top"
              title="辖区"
              :close-delay="120"
              @show="areasFormatter(scope.row.AreaIdCascades)"
            >
              <center>{{ areaScope === "" ? "暂未设置" : areaScope }}</center>
              <div slot="reference">
                <el-tag size="mini">{{ scope.row.Org }}</el-tag>
              </div>
            </el-popover>
            <template v-else>
              {{ scope.row.Org }}
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="Typ"
          :formatter="typFormatter"
          label="用户类型"
          width="120"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="Name"
          label="负责人"
          align="center"
          header-align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="BlName"
          label="营业执照名称"
          align="center"
          header-align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="BlNo"
          label="营业执照编码"
          align="center"
          header-align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="owner_name"
          label="上级单位"
          width="150"
          show-overflow-tooltip
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="MaintainerId"
          :formatter="dataFormatter"
          label="维保人员"
          width="150"
          show-overflow-tooltip
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="Contact"
          label="联系人"
          align="center"
          header-align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="Mobile"
          label="联系电话"
          align="center"
          header-align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="Telephone"
          label="固定电话"
          align="center"
          header-align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="Desc"
          label="店铺介绍"
          align="center"
          header-align="center"
          show-overflow-tooltip
        />
        <el-table-column
          label="操作"
          align="center"
          header-align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <i
              title="编辑"
              @click="handleEdit(scope.$index, scope.row)"
              class="opt el-icon-edit"
            />
            <i
              title="删除"
              @click="handleDel(scope.$index, scope.row)"
              class="opt el-icon-delete"
            />
          </template>
        </el-table-column>
      </el-table>
    </el-col>

    <!-- 工具条 -->
    <el-col :span="24" class="toolbar">
      <el-button
        type="danger"
        size="mini"
        @click="bulkRemove"
        :disabled="sels.length === 0"
      >批量删除
      </el-button
      >
      <el-button
        type="primary"
        size="mini"
        @click="bulkUserCreate"
        :disabled="sels.length === 0"
      >生成账户
      </el-button
      >
      <el-pagination
        small
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="filter.page"
        :page-sizes="filter.pageSizes"
        layout="total, sizes, prev, pager, next, jumper"
        :total="datalist.total"
        style="display: inline-block; margin-left: 15px"
      >
      </el-pagination>
    </el-col>

    <!--编辑界面-->
    <el-dialog
      :title="dlg.title"
      :visible.sync="dlg.visible"
      @close="handlerClose"
      width="500px"
    >
      <el-form
        :model="formData"
        label-width="120px"
        :rules="formRule"
        ref="form"
        size="mini"
      >
        <el-form-item prop="Org" label="单位名称">
          <el-input @change="hasChange" v-model.trim="formData.Org"/>
        </el-form-item>
        <el-form-item prop="Typ" label="单位类型">
          <el-select
            @change="hasChange"
            v-model.trim="formData.Typ"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.K"
              :label="item.V"
              :value="item.K"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="formData.Typ === 4" prop="Address" label="单位地址">
          <el-input @change="hasChange" v-model.trim="formData.Address"/>
        </el-form-item>
        <el-form-item prop="Pid" label="上级单位">
          <el-cascader
            placeholder="关键字"
            v-model.trim="formData.Pid"
            @change="handlePidChange"
            :options="customerTree"
            :props="props"
            clearable
            filterable
          />
        </el-form-item>

        <el-form-item prop="MaintainerId" label="维保人员">
          <el-select
            clearable
            placeholder="请选择"
            @change="hasChange"
            v-model.trim="formData.MaintainerId"
          >
            <el-option
              v-for="item in maintenanceList"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="负责区域"
          v-if="formData.Typ === 2 || formData.Typ === 3"
        >
          <el-cascader
            placeholder="关键字"
            v-model.trim="formData.Areas"
            @change="hasChange"
            :options="areaTree"
            :props="multiProps"
            clearable
            filterable
          />
        </el-form-item>
        <el-form-item v-if="formData.Typ === 4" label="筛选配置">
          <el-switch @change="hasChange" :active-value="1" :inactive-value="2" active-text="显示" inactive-text="不显示" v-model="formData.IsShow"></el-switch>
        </el-form-item>
        <el-form-item prop="Contact" label="联系人">
          <el-input @change="hasChange" v-model.trim="formData.Contact"/>
        </el-form-item>
        <el-form-item prop="BlNo" label="营业执照号">
          <el-input @change="hasChange" v-model.trim="formData.BlNo"/>
        </el-form-item>
        <el-form-item prop="BlName" label="营业执照名称">
          <el-input @change="hasChange" v-model.trim="formData.BlName"/>
        </el-form-item>
        <el-form-item prop="Mobile" label="联系电话">
          <el-input @change="hasChange" v-model.trim="formData.Mobile"/>
        </el-form-item>
        <el-form-item prop="Telephone" label="固定电话">
          <el-input @change="hasChange" v-model.trim="formData.Telephone"/>
        </el-form-item>
        <el-form-item prop="Desc" label="店铺介绍">
          <el-input
            type="textarea"
            :rows="2"
            @change="hasChange"
            v-model.trim="formData.Desc"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click.native="dlg.visible = false"
        >取消
        </el-button
        >
        <el-button type="primary" size="mini" @click.native="handleSubmit"
        >提交
        </el-button
        >
      </div>
    </el-dialog>
  </section>
</template>

<script>
import {mapState} from "vuex";
import {
  stg,
  getUserInfo,
  ifNull,
  toIntArray,
  export_json,
  loadData,
  referToMap,
  join,
  getPname,
  dateFormater,
  dataFormatter,ownerFilter,treeFilter
} from "@/util/index";
import {mobileValidator} from "@/util/validate";

export default {
  data() {
    return {
      changeState: false,
      index: 0, // 记录更新index
      sels: [], // 列表选中列
      loading: false,
      info: null,
      datalist: {},
      options: [],
      exportList: [],
      referMap: {},
      areaScope: null,
      dlg: {
        visible: false,
        title: "",
        state: null,
        typ: null,
      },
      filter: {
        // val: null,
        field: "Org",
        Type: "4", // 用户类型 typ 1 系统类型；2 经销代理；3 行政单位；4 餐饮单位
        StartAt: 0,
        Size: 20,
        page: 1,
        size: 20,
        pageSizes: [20, 30, 50],
        Name: "", // 负责人
        Org: "", // 单位名称
        Mobile: "", // 联系电话
        Pid: undefined,
        Areas: undefined,
      },
      formData: {},
      formRule: {
        Org: [{required: true, message: "单位名称不可为空", trigger: "blur"}],
        Name: [{required: true, message: "负责人不可为空", trigger: "blur"}],
        Mobile: [
          {required: false, trigger: "blur", validator: mobileValidator},
        ],
        Typ: [{required: true, trigger: "blur", message: "单位类型不可为空"}],
        // Desc: [
        //   { required: true, trigger: "blur", message: "店铺介绍不可为空" },
        // ],
      },
      maintenanceList: [], // 维保人员列表
    };
  },
  computed: {
    ...mapState({
      props: (state) =>
        Object.assign({}, state.props, {label: "Org", checkStrictly: true}),
      multiProps: (state) =>
        Object.assign({}, state.props, {multiple: true, checkStrictly: true}),
      selAreaProps: (state) =>
        Object.assign({}, state.props, {checkStrictly: true}),
    }),
    ...mapState([
      "clientHeight",
      "areaTree",
      "area",
      "customerTree",
      "customer",
    ]),
  },
  created() {
    this.info = getUserInfo().Info;
    this.$get("admin/listRoleType").then((res) => {
      this.options = res;
      this.referMap = referToMap(res);
    });
    this.getData();

    // 获取维保人员列表
    this.$get("admin/listMaintainer").then((res) => {
      this.maintenanceList = res;
    });
  },
  methods: {
    /**
     * @description tab点击
     */
    handleTabClick(tab, e) {
      this.filter.Type = tab.name;
      this.filter.page = 1;
      this.getData();
    },
    getPname(r, c) {
      return getPname(this.customerTree, r[c.property], "Org").join("/");
    },
    dataFormatter(r, c) {
      return dataFormatter(r[c.property], this.maintenanceList);
    },
    dateFormat(r, c) {
      return dateFormater(r[c.property], true);
    },
    hasChange() {
      this.changeState = true;
    },
    getData: function () {
      this.loading = true;
      const filter = JSON.parse(JSON.stringify(this.filter));
      const tpl = {
        page: filter.page,
        perpage: filter.size,
        Typ: filter.Type * 1
      }
      if (filter.field && filter.val) {
        tpl[filter.field] = filter.val;
      }
      if (filter.Pid) {
        tpl.Pid = join(filter.Pid);
      }
      if (
        (filter.Type == 2 || filter.Type == 3) &&
        filter.Areas
      ) {
        tpl.AreaId = filter.Areas[filter.Areas.length - 1];
      }
      this.$post("admin/queryCustomer", tpl)
        .then(res => {
          this.datalist.content = res.content;
          this.datalist.total = res.resultsPageInfo.Total;
          this.exportList = res.content;
          this.$set(
            this.datalist,
            "content",
            res.content
          );
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
      // this.datalist.content = this.customer;
      // if (this.filter.Type) {
      //   this.datalist.content = this.customer.filter(
      //     (v, _) => v["Typ"] == this.filter.Type
      //   );
      // }
      // if (this.filter.field && this.filter.val) {
      //   this.datalist.content = this.customer.filter(
      //     (v, _) => v[this.filter.field].indexOf(this.filter.val) !== -1
      //   );
      // }
      // if (this.filter.Pid) {
      //   let Pid = join(this.filter.Pid);
      //   this.datalist.content = this.datalist.content.filter((v, _) =>
      //     v.Pid.startsWith(Pid)
      //   );
      // }
      // if (
      //   (this.filter.Type == 2 || this.filter.Type == 3) &&
      //   this.filter.Areas
      // ) {
      //   let Areas = join(this.filter.Areas);
      //   this.datalist.content = this.datalist.content.filter((v, _) => {
      //     // v.Areas.startsWith(Areas)
      //     let sta =
      //       v.Areas && v.Areas !== ""
      //         ? v.Areas.split(",").some((item) => {
      //             return Areas.startsWith(item);
      //           })
      //         : false;
      //     if (sta) {
      //       return v;
      //     }
      //   });
      // }
      // this.datalist.total = this.datalist.content.length;
      // this.exportList = this.datalist.content;
      // this.$set(
      //   this.datalist,
      //   "content",
      //   this.datalist.content.slice(
      //     (this.filter.page - 1) * this.filter.size,
      //     this.filter.page * this.filter.size
      //   )
      // );
      // this.loading = false;
    },
    areasFormatter(areas) {
      this.areaScope = (areas || [])
        .map((e) => {
          for (let i = 0, len = this.area.length; i < len; i++) {
            if (
              e ===
              `${this.area[i].Pid === "" ? "/" : this.area[i].Pid}${
                this.area[i].Id
              }/`
            ) {
              return this.area[i].Name;
            }
          }
        })
        .join("/");
    },
    handleExport() {
      const info = [];
      this.exportList.forEach((e) => {
        info.push({
          单位名称: e.Org,
          联系人: e.Contact,
          上级单位: getPname(this.customerTree, e.Pid, "Org").join("/"),
          联系电话: e.Mobile,
          固定电话: e.Telephone,
          单位类型: this.typTransformer(e.Typ),
        });
      });
      export_json("客户信息", info, {
        单位名称: "单位名称",
        联系人: "联系人",
        上级单位: "上级单位",
        联系电话: "联系电话",
        固定电话: "固定电话",
        单位类型: "单位类型",
      });
    },
    handlePidChange(v) {
      this.changeState = true;
      if (this.formData.Id === v[v.length - 1]) {
        this.$message.warning(`选择错误，请重新选择`);
        this.formData.Pid = [];
      }
    },
    typTransformer(k) {
      return this.referMap[k];
    },
    typFormatter(r, c) {
      return this.typTransformer(r[c.property]);
    },
    handleRefresh() {
      this.filter.page = 1;
      this.filter.val = null;
      this.filter.Pid = undefined;
      loadData(
        this,
        (res) => {
          this.$store.dispatch("setCustomer", res).then(() => {
            this.getData();
          });
        },
        1
      );
    },
    selsChange: function (sels) {
      this.sels = sels;
    },
    // 改变页容量
    handleSizeChange(size) {
      this.filter.page = 1;
      this.filter.size = size;
      this.getData();
    },
    // 翻页
    handleCurrentChange(page) {
      this.filter.page = page;
      this.getData();
    },
    handleAdd: function () {
      this.dlg.title = "新增";
      this.dlg.visible = true;
      this.dlg.state = true;
      this.formData = {Pid: []};
    },
    handleEdit: function (index, row) {
      this.dlg.title = "编辑";
      this.dlg.visible = true;
      this.dlg.state = false;
      this.index = index;
      let userInfo = getUserInfo().Info
      this.$post("admin/queryCustomer", {Id: row.Id}).then((res) => {
        const resData = res.content[0];
        this.formData = Object.assign({}, resData, {
          Pid: resData.Pid === "" ? [] : ownerFilter(resData.Pid, userInfo),
        });
        let areaArr = [];
        if (resData.Areas && resData.Areas.length > 0) {
          areaArr.push(toIntArray(resData.Areas[0].FullId));
        }
        this.formData.Areas = areaArr;
      });
    },
    bulkUserCreate() {
      const user = [];
      this.sels.forEach((item) => {
        user.push({
          Name: item.Name,
          Typ: item.Typ,
          Mobile: item.Mobile,
          EntityId: `${ifNull(item.Pid) + item.Id}/`,
        });
      });
      this.$post("admin/createuser", user);
    },
    // 批量删除
    bulkRemove: function () {
      const ids = this.sels.map((item) => item.Id).toString();
      this.$confirm("确认删除选中记录吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          this.$post("admin/bulkRemoveCustomer", {ids: ids})
            .then((res) => {
              this.$store.dispatch("clearCustomer")
              this.$get('admin/listBasicDataOfCustomer').then(res => {
                this.$store.dispatch('setCustomer', res.Customer)
                this.$store.dispatch('setCustomerTree', treeFilter(res.Customer))
              })
              this.getData();
            })
            .catch(() => {
            });
        })
        .catch(() => {
        });
    },
    handleDel: function (index, row) {
      this.$confirm("确认删除该记录吗?", "提示", {
        type: "warning",
      })
        .then(() => {
          const para = {id: row.Id};
          this.$get("admin/delCustomer", para)
            .then(() => {
              this.$store.dispatch("clearCustomer")
              this.$get('admin/listBasicDataOfCustomer').then(res => {
                const user = JSON.parse(stg().getItem('user'))
                var customerArray = Array.from(res.Customer)
                if (user.Info !== null) {
                  let customer = {
                    Address: user.Info.address,
                    CreateAt: user.Info.create_at,
                    Name: user.Info.name,
                    Org: user.Info.org,
                    Pid: user.Info.pid,
                    Id: user.Info.id,
                    Mobile: user.Info.mobile,
                    Status: user.Info.status,
                    Typ: user.Info.typ
                  }
                  customerArray.push(customer)
                }
                this.$store.dispatch('setCustomer', customerArray)
                this.$store.dispatch('setCustomerTree', treeFilter(customerArray))
              })
              this.getData();
            })
            .catch(() => {
            });
        })
        .catch(() => {
        });
    },
    handleSubmit: function () {
      if (!this.changeState) {
        this.dlg.visible = false;
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {})
            .then(() => {
              this.formData.Pid = join(this.formData.Pid);
              const para = Object.assign({}, this.formData);
              para.Name = para.Org
              if (
                (para.Typ === 2 || para.Typ === 3) &&
                para.Areas && para.Areas.length !== 0
              ) {
                const areaIds = [];
                if (this.info !== null) {
                  const amap = {};
                  const as = this.info["areas"];
                  let str, len;
                  as.split(",").forEach((e) => {
                    str = e.substr(0, e.length - 1);
                    len = str.lastIndexOf("/");
                    amap[str.substr(1 - len)] = e.substr(0, len + 1);
                  });
                  para.Areas.forEach((e) =>
                    areaIds.push(`${amap[e[0]]}${e.join("/")}/`)
                  );
                } else {
                  para.Areas.forEach((e) => areaIds.push(`/${e.join("/")}/`));
                }
                para.AreaIdCascades = areaIds;
              }
              delete para.Areas;
              this.$post("admin/saveCustomer", para)
                .then(() => {
                  this.$get('admin/listBasicDataOfCustomer').then(res => {
                    const user = JSON.parse(stg().getItem('user'))
                    var customerArray = Array.from(res.Customer)
                    if (user.Info !== null) {
                      let customer = {
                        Address: user.Info.address,
                        CreateAt: user.Info.create_at,
                        Name: user.Info.name,
                        Org: user.Info.org,
                        Pid: user.Info.pid,
                        Id: user.Info.id,
                        Mobile: user.Info.mobile,
                        Status: user.Info.status,
                        Typ: user.Info.typ
                      }
                      customerArray.push(customer)
                    }
                    this.$store.dispatch('setCustomer', customerArray)
                    this.$store.dispatch('setCustomerTree', treeFilter(customerArray))
                  })
                  this.dlg.visible = false;
                  this.getData();
                })
                .catch(() => {
                });
            })
            .catch(() => {
            });
        }
      });
    },
    handlerClose: function () {
      if (this.changeState) {
        this.changeState = false;
        this.getData();
      }
      if (this.$refs["form"]) {
        this.$refs["form"].resetFields();
      }
    },
  },
};
</script>
<style scoped>
::v-deep .el-input .el-input--suffix {
  width: 96px;
}
</style>
