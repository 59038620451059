/**
 * Created by jiachenpan on 16/11/18.
 */

export function isvalidUsername(str) {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}

/* 合法uri*/
export function validateURL(textval) {
  const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return urlregex.test(textval)
}

/* 小写字母*/
export function validateLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/* 大写字母*/
export function validateUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/* 大小写字母*/
export function validatAlphabets(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

/* 邮箱 */
function testEmail(str) {
  const reg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/
  return reg.test(str)
}

/* 手机号 */
function testMobile(str) {
  if (str) {
    const reg = /^1\d{10}$/
    return reg.test(str)
  }
  return true
}

export const emailValidator = (rule, value, callback) => {
  if (!testEmail(value)) {
    callback(new Error('邮箱格式有误'))
  } else {
    callback()
  }
}

export const mobileValidator = (rule, value, callback) => {
  if (!testMobile(value)) {
    callback(new Error('手机号格式有误'))
  } else {
    callback()
  }
}
export const loginValidator = (rule, value, callback) => {
  if (!testMobile(value) && !testEmail(value)) {
    callback(new Error('用户名格式有误'))
  } else {
    callback()
  }
}
export const validatePass = (rule, value, callback) => {
  if (value.length < 5) {
    callback(new Error('密码不能少于5位'))
  } else {
    callback()
  }
}
